import React, { useEffect, useState } from "react";
import Api from "../../api/api.js";
import Customize from "./Customize.js";
import Loading from "../../components/loading.js";
import HardDeleteModel from "./HardDeleteModel.js";
import Addscomponent from "./Addscomponent.js";
import EditModel from "./RestoreModel.js";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { Link } from "react-router-dom";
import Popover from "../Terms/Popover .js";
import Pagination from "./Pagination.js";
import Model from "../../components/model.js";
import Select from "react-select";
import toast from "react-hot-toast";

export const QR = (props) => {
  const [Load, setLoad] = useState(false);
  const [Data, setData] = useState(false);
  const [number, setNumber] = useState(3);
  const [meta, setMeta] = useState(3);
  const [pageNumber, setpageNumber] = useState(1);
  const [Per_page, setPer_page] = useState(50);
  const [search, setSearch] = useState("");
  const authHeader = useAuthHeader();
  const useHeader = useAuthHeader();
  const [loading, setLoading] = useState(false);
  const [gradesData, setGradesData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [typesData, setTypesData] = useState([]);
  const [isOpenModel, setOpenModel] = useState(false);
  const [qrData, setQrData] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [isFilterd, setIsFilterd] = useState(false);

  const [generateLoading, setGenerateLoading] = useState(false);

  const [formData, setFormData] = useState({
    grade_ids: [],
    subject_id: "",
    year: "",
    term_id: "",
  });

  // useEffect(() => {
  //   setLoading(true);

  //   Api.grades
  //     .getAllGrades(useHeader)
  //     .then((res1) => {
  //       //if true set the data
  //       if (res1.status) {
  //         setGradesData(res1.data);
  //       }

  //       return Api.Terms.getAll(useHeader);
  //     })
  //     .then((res3) => {
  //       if (res3.status) {
  //         setTermsData(res3.data);
  //       }
  //       return Api.Years.getAll(useHeader);
  //     })
  //     .then((res4) => {
  //       if (res4.status) {
  //         setYearsData(res4.data);
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {});
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Fetch all types
        const resTypes = await Api.Type.getAll(useHeader);
        if (resTypes.status) {
          setTypesData(resTypes.data);
        }

        // Fetch all grades
        const resGrades = await Api.grades.getAllGrades(useHeader);
        if (resGrades.status) {
          setGradesData(resGrades.data);
        }

        // Fetch all terms
        const resTerms = await Api.Terms.getAll(useHeader);
        if (resTerms.status) {
          setTermsData(resTerms.data);
        }

        // Fetch all years
        const resYears = await Api.Years.getAll(useHeader);
        if (resYears.status) {
          setYearsData(resYears.data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChangeGrades = (grades) => {
    setFormData({
      ...formData,
      grade_ids: grades,
    });
    Api.subjects
      .getSubjectByGradeId(useHeader, {
        grade_ids: grades?.map((g) => {
          return g.id;
        }),
      })
      .then((res) => {
        if (res.status) {
          setSubjectsData(res?.data);
        }
      })
      .catch((err) => {});
  };

  // Utility function to prepare new FormData with selected fields
  const prepareFormData = (formData) => {
    const newFormData = new FormData();

    if (formData.subject_id) {
      newFormData.append("subject_id", formData.subject_id);
      // console.log("Appending subject_id:", formData.subject_id);
    }

    if (formData.year) {
      newFormData.append("year", formData.year);
      // console.log("Appending year:", formData.year);
    }

    if (formData.term_id) {
      newFormData.append("term_id", formData.term_id);
      // console.log("Appending term_id:", formData.term_id);
    }
    if (formData.type_id) {
      newFormData.append("type_id", formData.type_id);
      // console.log("Appending type_id:", formData.type_id);
    }
    if (formData.active) {
      newFormData.append("active", formData.active);
      // console.log("Appending active:", formData.active);
    }

    if (formData.grade_ids && formData.grade_ids.length > 0) {
      const gradeIds = JSON.stringify(formData.grade_ids.map((g) => g.id));
      newFormData.append("grade_id", gradeIds);
      // console.log("Appending grade_id:", gradeIds);
    }
    // if (formData.grade_ids && formData.grade_ids.length > 0) {
    //   newFormData.append(
    //     "grade_id",
    //     formData.grade_ids.map((g) => g.id)
    //   );
    //   console.log(
    //     "Appending grade_ids:",
    //     formData.grade_ids.map((g) => g.id)
    //   );
    // }

    // newFormData.append("page", 3);

    return newFormData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setGenerateLoading(true);

    // Prepare data for submission

    const submissionData = prepareFormData(formData);

    setGenerateLoading(false);

    let entryCount = 0;
    for (let entry of submissionData.entries()) {
      entryCount++;
    }
    // console.log("Number of entries in newFormData:", entryCount);

    // Check if submissionData is empty
    if (entryCount === 0) {
      toast.error(
        "Please select at least one required field: Subject, Year, Term, or Grade."
      );
    }
    // console.log("handle submit function");
    // Proper way to log FormData contents
    // for (let [key, value] of submissionData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    Api.qr
      .getqrnum(authHeader, submissionData)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setMeta(res.meta);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });

    setIsFilterd(true);
  };

  const handleCallback = (data) => {
    setpageNumber(data);
  };
  const handlePer_page = (data) => {
    setPer_page(data);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(qrData?.QR_link || "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000); // Reset copied state after 2 seconds
  };

  // const handleDeleteClick = (id) => {
  //   console.log("Delete button clicked");
  //   console.log(`qr Id : ${id}`);
  //   // console.log(authHeader);

  //   Api.qr
  //     .deleteQr(authHeader, { id })
  //     .then((res) => {
  //       console.log("Response data:", res.data);
  //       fetchingData();
  //     })
  //     .catch((err) => {
  //       console.error("Error deleting QR:", err);
  //     });
  // };

  const handleDeactivateClick = (id) => {
    // console.log("Dactivate button clicked");
    console.log(`qr Id : ${id}`);
    // console.log(authHeader);

    Api.qr
      .deactiveQr(authHeader, { id })
      .then((res) => {
        console.log("Response data:", res.data);
        fetchingData();
      })
      .catch((err) => {
        console.error("Error deactivat QR:", err);
      });
  };
  const handleActivateClick = (id) => {
    console.log("Active button clicked");
    console.log(`qr Id : ${id}`);
    // console.log(authHeader);

    Api.qr
      .activeQr(authHeader, { id })
      .then((res) => {
        console.log("Response data:", res.data);
        fetchingData();
      })
      .catch((err) => {
        console.error("Error deactivat QR:", err);
      });
  };

  // const confirmDeletion = (id) => {
  //   toast(
  //     (t) => (
  //       <span className="flex items-center space-x-2">
  //         <span>Are you sure you want to delete this QR?</span>
  //         <button
  //           onClick={() => {
  //             handleDeleteClick(id);
  //             toast.dismiss(t.id);
  //           }}
  //           className="bg-red-500 text-white p-2 rounded"
  //         >
  //           Confirm
  //         </button>
  //         <button
  //           onClick={() => toast.dismiss(t.id)}
  //           className="bg-primary text-white p-2 rounded"
  //         >
  //           Cancel
  //         </button>
  //       </span>
  //     ),
  //     { duration: 5000 } // Keeps the toast visible longer for user action
  //   );
  // };
  const fetchingData = () => {
    // console.log("fetching Data function");

    let data = {
      page: pageNumber,
      per_page: 50,
    };

    if (isFilterd) {
      const submissionData = prepareFormData(formData);

      // Convert FormData to a plain object
      const submissionDataObject = {};
      submissionData.forEach((value, key) => {
        submissionDataObject[key] = value;
      });

      data = {
        ...submissionDataObject,
        page: pageNumber,
        per_page: 50,
      };
    }

    // console.log(data);
    setLoad(true);

    Api.qr
      .getqrnum(authHeader, data)
      .then((res) => {
        console.log(res.data);
        // console.log(res.meta);
        setData(res.data);
        setMeta(res.meta);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  useEffect(() => {
    document.title = "QR system - QR list";
    if (search) searchQr(pageNumber);
    else fetchingData();
  }, [pageNumber, Per_page]);

  const searchQr = (pageNum) => {
    const data = { search, page: pageNum };
    setLoad(true);

    Api.qr
      .searchQr(authHeader, data)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setMeta(res.meta);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const handleChange = (event) => {
    // if (event.target.name === "grade_ids") {
    //   Api.subjects
    //     .getSubjectByGradeId(event.target.value, useHeader)
    //     .then((res) => {
    //       if (res.status) {
    //         setSubjectsData(res?.data?.subjects);
    //       }
    //     })
    //     .catch((err) => {});
    // }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-between py-3 px-3">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            {Customize.name}
          </p>
        </div>
        <div className="flex flex-row items-end gap-3 px-3">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            type="text" // Set input type to "text" for a text field
          />
          <button
            className="px-2 sm:px-4 py-2 bg-white border rounded-md cursor-pointer hover:bg-gray-200"
            onClick={() => {
              searchQr(1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="overflow-x-auto m-auto px-5 ">
        <div className="flex   justify-cent      flex-col ">
          {/* <h1 className=" text-3xl p-5 m-auto">add limit</h1> */}
          {/* <div className="flex flex-row gap-1 ">
            <input
              className="w-52 h-10 border  ml-10  mb-4 border-gray-300 rounded "
              value={number}
              type="number"
              onChange={(e) => setNumber(e.target.value)}
            />
            <button
              className="bg-primary h-10 text-white p-1 rounded"
              onClick={fetchingData}
            >
              research
            </button>
          </div> */}
        </div>
        {/* Forms */}
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="w-100 grid grid-cols-1 sm:grid-cols-2 pt-10 gap-10 bg-white p-2 rounded-lg">
            {/* Grade Selection */}
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="grade_ids" className="text-sm font-medium">
                Grade:<span className="text-red-600">*</span>
              </label>
              <Select
                isMulti
                id="grade_ids"
                name="grade_ids"
                defaultValue={formData.grade_ids}
                value={formData.grade_ids}
                className="flex-grow"
                placeholder="-- Select Grade --"
                onChange={handleChangeGrades}
                options={gradesData}
                isSearchable
                isClearable
                getOptionLabel={(option) => option.g_name}
                getOptionValue={(option) => option.id}
              />
            </div>

            {/* Subject Selection */}
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="subject_id" className="text-sm font-medium">
                Subject:<span className="text-red-600">*</span>
              </label>
              <select
                id="subject_id"
                name="subject_id"
                value={formData.subject_id}
                onChange={handleChange}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              >
                <option value="">-- Select Subject --</option>
                {subjectsData.map((subject) => (
                  <option key={subject.id} value={subject.id}>
                    {subject.s_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Term Selection */}
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="term_id" className="text-sm font-medium">
                Term:<span className="text-red-600">*</span>
              </label>
              <select
                id="term_id"
                name="term_id"
                value={formData.term_id}
                onChange={handleChange}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              >
                <option value="">-- Select Term --</option>
                {termsData.map((term) => (
                  <option key={term.id} value={term.id}>
                    {term.t_name}
                  </option>
                ))}
              </select>
            </div>

            {/* Year Selection */}
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="year" className="text-sm font-medium">
                Year:<span className="text-red-600">*</span>
              </label>
              <select
                id="year"
                name="year"
                value={formData.year}
                onChange={handleChange}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              >
                <option value="">-- Select Year --</option>
                {yearsData.map((year) => (
                  <option key={year.id} value={year.y_name}>
                    {year.y_name}
                  </option>
                ))}
              </select>
            </div>
            {/* Types Selection */}
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="type_id" className="text-sm font-medium">
                Type:<span className="text-red-600">*</span>
              </label>
              <select
                id="type_id"
                name="type_id"
                value={formData.type_id}
                onChange={handleChange}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              >
                <option value="">-- Select Type --</option>
                {typesData.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            {/* active Selection */}
            <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
              <label htmlFor="active" className="text-sm font-medium">
                Active:<span className="text-red-600">*</span>
              </label>
              <select
                id="active"
                name="active" // Changed from type_id to active_status
                value={formData.active} // Make sure this matches your formData structure
                onChange={handleChange}
                className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
              >
                <option value="">-- Select Activation --</option>
                <option value="1">Active</option> {/* Value for Active */}
                <option value="0">Inactive</option> {/* Value for Inactive */}
              </select>
            </div>

            {/* Submit Button */}
            <div className="col-span-2 flex justify-end pt-5">
              <button
                type="submit"
                className="w-fit py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
              >
                {generateLoading ? (
                  <Loading size="sm" color="text-white" />
                ) : (
                  "Filter"
                )}
              </button>
            </div>
          </div>
        </form>
        <div>{/* <Addscomponent /> */}</div>
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden -6">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="px-4 py-2 text-left">#</th>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Title</th>
              <th className="px-4 py-2 text-left">Grades</th>
              <th className="px-4 py-2 text-left">Term</th>
              <th className="px-4 py-2 text-left">Subject</th>
              {/* <th className="px-4 py-2 text-left">tree</th> */}
              <th className="px-4 py-2 text-left">Link Type</th>
              <th className="px-4 py-2 text-left">Year</th>
              {/* <th className="px-4 py-2 text-left">Clicks</th>
              <th className="px-4 py-2 text-left">Downloads</th> */}
              <th className="px-4 py-2 text-center">Active</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600">
            {Data?.length === 0 ? (
              <tr>
                <th></th>
                <th>No data data found</th>
                <th></th>
              </tr>
            ) : null}
            {Load ? (
              <tr>
                <th></th>
                <th>
                  <Loading />
                </th>
                <th></th>
              </tr>
            ) : (
              <>
                {Data &&
                  Data?.map((item, index) => (
                    <tr
                      key={item.qr}
                      // className="transition-all hover:bg-gray-100 border-b border-gray-200"
                      // ${
                      //   item.active ? "hover:bg-gray-100" : "bg-red-200"
                      // }
                      className={`transition-all border-b border-gray-200 hover:bg-gray-100 `}
                    >
                      <td className="px-4 py-2">{index + 1}</td>
                      <td className="px-4 py-2">
                        {item?.order_num}_{item?.year}
                      </td>
                      <td className="px-4 py-2">{item.title}</td>
                      <td className="px-4 py-2 flex flex-row gap-x-2 ">
                        {item.grades.map((g) => (
                          <span
                            className={`px-2 rounded-md ${
                              item.active ? "bg-gray-300" : ""
                            }`}
                          >
                            {g.g_name}
                          </span>
                        ))}
                      </td>
                      <td className="px-4 py-2 text-left ">
                        {item.term?.t_name}
                      </td>
                      <td className="px-4 py-2 text-left ">
                        {item.subject?.s_name}
                      </td>
                      {/* <td className="px-4 py-2 text-left ">{item.tree}</td> */}
                      <td className="px-4 py-2 text-left ">
                        {item.typelink?.name}
                      </td>
                      <td className="px-4 py-2 text-left ">{item?.year}</td>
                      {/* <td className="px-4 py-2 text-left ">{item?.clicks}</td> */}
                      {/* <td className="px-4 py-2 text-left ">
                        {item?.downloads}
                        </td> */}
                      <td className="px-4 py-2 text-left ">
                        {/* Status*/}
                        <div className="flex items-center justify-center">
                          <div
                            className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer transition-colors ${
                              item.active ? "bg-green-500" : "bg-red-500"
                            }`}
                            onClick={() => {
                              item.active
                                ? handleDeactivateClick(item.id)
                                : handleActivateClick(item.id);
                            }}
                          >
                            <div
                              className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform ${
                                item.active ? "translate-x-full" : ""
                              }`}
                            ></div>
                          </div>
                        </div>
                      </td>
                      <td className="px-4 py-2 text-left ">
                        <div className="w-full flex flex-row gap-1 flex-wrap">
                          {/*trash icon */}
                          {/* <button
                            className="w-6 h-6"
                            onClick={() => confirmDeletion(item.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 6h18M9 6V4a1 1 0 011-1h4a1 1 0 011 1v2m5 0v12a2 2 0 01-2 2H6a2 2 0 01-2-2V6h16z"
                              />
                            </svg>
                          </button> */}
                          {/* <button
                            className="w-6 h-6"
                            
                          >
                            {item.active ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0 1 18 14V11a6.002 6.002 0 0 0-4-5.659V5a2 2 0 1 0-4 0v.341C7.67 6.165 6 8.388 6 11v3c0 .379-.214.725-.595.895L4 17h5m7 0v1a3 3 0 1 1-6 0v-1m9-2a9.98 9.98 0 0 0-3.516-7.484M3.27 3.27l17.46 17.46"
                                />
                              </svg>
                            ) : (
                              // Active Icon

                              // Notification Off Icon
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                fill="none"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0 1 18 14V11a6.002 6.002 0 0 0-4-5.659V5a2 2 0 1 0-4 0v.341C7.67 6.165 6 8.388 6 11v3c0 .379-.214.725-.595.895L4 17h5m3 5a3 3 0 0 0 3-3H9a3 3 0 0 0 3 3z"
                                />
                              </svg>
                            )}
                          </button> */}
                          <Link to={`/qr-info/${item.qr}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </Link>
                          <a
                            className="m-auto"
                            target="_blank"
                            href={item.QR_link}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                              />
                            </svg>
                          </a>
                          {/* <button
                            className="m-auto"
                            onClick={() => {
                              handleOpenModel(item);
                            }}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                              />
                            </svg>
                          </button> */}
                          <a
                            className="m-auto"
                            target="_blank"
                            href={item.SVG_link}
                          >
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-6 h-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                              />
                            </svg>
                          </a>
                          <a
                            className="m-auto"
                            target="_blank"
                            href={item.EPS_link}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                              />
                            </svg>
                          </a>

                          <Link to={`/Update_qr_info/${item.qr}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                        </div>

                        {/* <a
                                  className="m-auto"
                                  target="_blank"
                                  href={item.Excel_link}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                                    />
                                  </svg>
                                </a> */}
                      </td>
                    </tr>
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        callback={handleCallback}
        set_per_page={handlePer_page}
        per_page={meta?.per_page}
        current_page={meta?.current_page}
        total={meta?.total}
        last_page={meta?.last_page}
      />
      <Model
        isOpen={isOpenModel}
        onClose={() => {
          setOpenModel(false);
          setQrData({});
        }}
        title="QR Details"
      >
        <div className="flex flex-col gap-4 justify-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-6 gap-y-3">
            <div className="col ">
              <p className="font-semibold text-lg">Qr Title</p>
              <p className=" text-base text-stone-700">{qrData?.title}</p>
            </div>
            <div className="col">
              <p className="font-semibold text-lg"> Grades</p>
              <p className=" text-base text-stone-700">
                {qrData?.grades?.map((g) => (
                  <span className="bg-gray-300 px-2 rounded-md">
                    {g.g_name}
                  </span>
                ))}
              </p>
            </div>
            <div className="col">
              <p className="font-semibold text-lg">Term</p>
              <p className=" text-base text-stone-700">
                {qrData?.term?.t_name}
              </p>
            </div>
            <div className="col">
              <p className="font-semibold text-lg">Subject</p>
              <p className=" text-base text-stone-700">
                {qrData?.subject?.s_name}
              </p>
            </div>
            <div className="col">
              <p className="font-semibold text-lg">Year</p>
              <p className=" text-base text-stone-700">{qrData?.year}</p>
            </div>
            <div className="col">
              <p className="font-semibold text-lg">Link Type</p>
              <p className=" text-base text-stone-700">
                {qrData?.typelink?.name}
              </p>
            </div>
            <div className="col">
              <p className="font-semibold text-lg">Link Content</p>
              <p className=" text-base text-stone-700">{qrData?.Content}</p>
            </div>
          </div>
          <img
            src={qrData.SVG_link}
            className="max-w-48 m-auto"
            alt={qrData?.title}
          />
          <div className="flex flex-col gap-1">
            <p className="font-semibold text-lg">Created Link</p>
            <p className=" text-sm text-stone-700">
              You can also copy and send it or share it on your social media. 🚀
            </p>
            <div className="flex flex-row gap-x-5">
              <div className=" grow min-w-max border border-stone-400 rounded-md flex flex-row gap-x-3 px-3">
                <span className="grow my-auto text-stone-400 text-ellipsis">
                  {qrData?.QR_link}
                </span>
                <button className="grow-0 text-primary" onClick={handleCopy}>
                  {isCopied ? "Copied!" : "Copy Link"}
                </button>
              </div>
              <button className="grow-0 min-w-fit bg-primary text-white px-5 py-2 rounded-md">
                {" "}
                Download Zip
              </button>
            </div>
          </div>
        </div>
      </Model>
    </div>
  );
};
