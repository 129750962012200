import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import { useEffect, useState } from "react";
import Loading from "../../components/loading";
import Api from "../../api/api";
import toast from "react-hot-toast";
import Model from "../../components/model";
import Select from "react-select";

export const Export = (props) => {
  const navigate = useNavigate();
  const useHeader = useAuthHeader();
  const [loading, setLoading] = useState(false);
  const [generateExcelLoading, setGenerateExcelLoading] = useState(false);
  const [generateZipLoading, setGenerateZipLoading] = useState(false);
  const [gradesData, setGradesData] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [subjectsData, setSubjectsData] = useState([]);
  const [typesData, setTypesData] = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isOpenZipModel, setIsOpenZipModel] = useState(false);
  const [isOpenPDFModel, setIsOpenPDFModel] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [zipData, setZipData] = useState(null);
  const [formData, setFormData] = useState({
    grade_ids: [],
    subject_id: "",
    year: "",
    term_id: "",
  });

  const API_URL_PRODUCTION = process.env.REACT_APP_API_URL_PRODUCTION;
  const API_URL_STAGING = process.env.REACT_APP_API_URL_STAGING;
  const API_URL_DEVELOPMENT = process.env.REACT_APP_API_URL_DEVELOPMENT;

  const APP_MODE = process.env.REACT_APP_APP_MODE; // production, staging, development
  const baseUrl =
    APP_MODE == "production"
      ? API_URL_PRODUCTION
      : APP_MODE == "staging"
      ? API_URL_STAGING
      : API_URL_DEVELOPMENT;
  const endpointPath = "/generate/pdf"; // Assuming this is the endpoint path
  function getFullUrl(params = {}) {
    const queryString = new URLSearchParams(params).toString();
    return `${baseUrl}${endpointPath}?${queryString}`;
  }

  // Utility function to prepare new FormData with selected fields
  const prepareFormData = (formData) => {
    const newFormData = new FormData();

    if (formData.subject_id) {
      newFormData.append("subject_id", formData.subject_id);
      console.log("Appending subject_id:", formData.subject_id);
    }

    if (formData.year) {
      newFormData.append("year", formData.year);
      console.log("Appending year:", formData.year);
    }

    if (formData.term_id) {
      newFormData.append("term_id", formData.term_id);
      console.log("Appending term_id:", formData.term_id);
    }
    if (formData.type_id) {
      newFormData.append("type_id", formData.type_id);
      console.log("Appending type_id:", formData.type_id);
    }
    if (formData.active) {
      newFormData.append("active", formData.active);
      console.log("Appending active:", formData.active);
    }

    if (formData.grade_ids && formData.grade_ids.length > 0) {
      const gradeIds = JSON.stringify(formData.grade_ids.map((g) => g.id));
      newFormData.append("grade_ids", gradeIds);
      console.log("Appending grade_id:", gradeIds);
    }
    // if (formData.grade_ids && formData.grade_ids.length > 0) {
    //   newFormData.append(
    //     "grade_id",
    //     formData.grade_ids.map((g) => g.id)
    //   );
    //   console.log(
    //     "Appending grade_ids:",
    //     formData.grade_ids.map((g) => g.id)
    //   );
    // }

    // newFormData.append("page", 3);

    return newFormData;
  };

  const handleChange = (event) => {
    // if (event.target.name === "grade_ids") {
    //   Api.subjects
    //     .getSubjectByGradeId(event.target.value, useHeader)
    //     .then((res) => {
    //       if (res.status) {
    //         setSubjectsData(res?.data?.subjects);
    //       }
    //     })
    //     .catch((err) => {});
    // }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeGrades = (grades) => {
    setFormData({
      ...formData,
      grade_ids: grades,
    });
    Api.subjects
      .getSubjectByGradeId(useHeader, {
        grade_ids: grades?.map((g) => {
          return g.id;
        }),
      })
      .then((res) => {
        if (res.status) {
          setSubjectsData(res?.data);
        }
      })
      .catch((err) => {});
  };

  const handleOpenModel = () => {
    setIsOpenModel(true);
  };

  const handleCloseModel = () => {
    setIsOpenModel(false);
  };
  const handleOpenZipModel = () => {
    setIsOpenZipModel(true);
  };

  const handleCloseZipModel = () => {
    setIsOpenZipModel(false);
  };
  const handleOpenPDFModel = () => {
    setIsOpenPDFModel(true);
  };

  const handleClosePDFModel = () => {
    setIsOpenPDFModel(false);
  };

  const createExcel = async (event) => {
    setGenerateExcelLoading(true); // Set loading state to true
    event.preventDefault(); // Prevent default form submission

    // Prepare newFormData with only selected fields
    const newFormData = prepareFormData(formData);

    // Count the number of entries in newFormData
    let entryCount = 0;
    for (let entry of newFormData.entries()) {
      entryCount++;
    }
    console.log("Number of entries in newFormData:", entryCount);

    // Check if newFormData is empty
    if (entryCount === 0) {
      toast.error(
        "Please select at least one required field: Subject, Year, Term, or Grade."
      );
      setGenerateExcelLoading(false); // Reset loading state
      return; // Exit the function early
    }

    // Proper way to log FormData contents
    for (let [key, value] of newFormData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      // Make the API call to generate the Excel file
      const res = await Api.excel.generateExcel(newFormData, useHeader); // Pass FormData directly

      // Check if res is defined
      if (res) {
        console.log("Response Status: ", res);
        // Log response status for debugging
        if (res.status) {
          // If the status is true, set Excel data and show success toast
          setExcelData(res["Excel Link"]);
          toast.success("EXCEL generated successfully");
          handleOpenModel(); // Open the modal to display the Excel link
        } else {
          // If the status is false, show the error message from the response
          toast.error(res.message || "An error occurred. Please try again");
        }
      } else {
        // Handle the case where the response is undefined
        toast.error("No response from server. Please try again.");
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error generating Excel:", error); // Log the error for debugging
      toast.error(error.message || "An error occurred. Please try again"); // Show a generic error message
    } finally {
      setGenerateExcelLoading(false); // Ensure loading state is reset in either case
    }
  };

  const createZipFile = async (event) => {
    setGenerateZipLoading(true); // Set loading state to true
    event.preventDefault(); // Prevent default form submission

    // Prepare newFormData with only selected fields

    const newFormData = prepareFormData(formData);

    // Check if newFormData is empty
    let entryCount = 0;
    for (let entry of newFormData.entries()) {
      entryCount++;
    }

    // Proper way to log FormData contents
    // for (let [key, value] of newFormData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }
    // If no fields are selected, show an error toast
    if (entryCount === 0) {
      toast.error(
        "Please select at least one required field: Subject, Year, Term, or Grade."
      );
      setGenerateZipLoading(false); // Reset loading state
      return; // Exit the function early
    }

    try {
      // Make the API call to generate the Zip file
      const res = await Api.zip.generateZip(newFormData, useHeader);

      // Check if the response is defined and has a status
      if (res) {
        console.log("Response Status: ", res); // Log response status for debugging

        if (res.status) {
          // If the status is true, set Zip data and show success toast
          setZipData(res["Zip File Link"]);
          toast.success("Zip File generated successfully");
          handleOpenZipModel(); // Open the modal to display the Zip file link
        } else {
          // If the status is false, show the error message from the response
          toast.error(res.message || "An error occurred. Please try again.");
        }
      } else {
        // Handle the case where the response is undefined
        toast.error("No response from server. Please try again.");
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error("Error generating Zip file:", error); // Log the error for debugging
      toast.error(error.message || "An error occurred. Please try again."); // Show a generic error message
    } finally {
      setGenerateZipLoading(false); // Ensure loading state is reset in either case
    }
  };

  const createPDF = async (event) => {
    setGenerateLoading(true); // Set loading state to true
    event.preventDefault(); // Prevent default form submission

    const newFormData = prepareFormData(formData);

    // Count the number of entries in newFormData
    let entryCount = 0;
    for (let entry of newFormData.entries()) {
      entryCount++;
    }
    // console.log("Number of entries in newFormData:", entryCount);

    // Check if newFormData is empty
    if (entryCount === 0) {
      toast.error(
        "Please select at least one required field: Subject, Year, Term, or Grade."
      );
      setGenerateLoading(false); // Reset loading state
      return; // Exit the function early
    }

    // Proper way to log FormData contents
    // for (let [key, value] of newFormData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      const response = await fetch(getFullUrl({}), {
        method: "POST",
        headers: {
          Authorization: useHeader, // Your token format
        },
        body: newFormData,
      });

      if (!response.ok) {
        // If the response is not OK (status code 200-299)
        const errorResponse = await response.json();
        // Parse the response body as JSON
        throw new Error(errorResponse.message || "An error occurred"); // Use the error message from the response
      }

      const blob = await response.blob();
      toast.success("PDF generated successfully");
      handleOpenPDFModel();
      setPdfData(blob);
    } catch (error) {
      console.error("Error viewing PDF:", error);
      toast.error(error.message); // Show the error message from the response
    } finally {
      setGenerateLoading(false); // Ensure loading state is reset
    }
  };

  // useEffect(() => {
  //   document.title = "QR system - Export";

  //   setLoading(true);
  //   Api.grades
  //     .getAllGrades(useHeader)
  //     .then((res1) => {
  //       if (res1.status) {
  //         setGradesData(res1.data);
  //       }

  //       return Api.Terms.getAll(useHeader);
  //     })
  //     .then((res3) => {
  //       if (res3.status) {
  //         setTermsData(res3.data);
  //       }
  //       return Api.Years.getAll(useHeader);
  //     })
  //     .then((res4) => {
  //       if (res4.status) {
  //         setYearsData(res4.data);
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     })
  //     .catch((err) => {});
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Fetch all types
        const resTypes = await Api.Type.getAll(useHeader);
        if (resTypes.status) {
          setTypesData(resTypes.data);
        }

        // Fetch all grades
        const resGrades = await Api.grades.getAllGrades(useHeader);
        if (resGrades.status) {
          setGradesData(resGrades.data);
        }

        // Fetch all terms
        const resTerms = await Api.Terms.getAll(useHeader);
        if (resTerms.status) {
          setTermsData(resTerms.data);
        }

        // Fetch all years
        const resYears = await Api.Years.getAll(useHeader);
        if (resYears.status) {
          setYearsData(resYears.data);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const downloadExcel = async () => {
    window.open(excelData, "_blank");
  };
  const downloadZip = async () => {
    window.open(zipData, "_blank");
  };
  const downloadPdf = async () => {
    if (!pdfData) {
      toast.error(
        "No PDF available to download yet. Please generate the PDF first."
      );
      return;
    }

    // Generate filename based on form data
    const gradesNames = formData.grade_ids
      .map((grade) => gradesData.find((g) => g.id == grade.id)?.g_name)
      .join("_")
      .replace(/ /g, "_");

    const subjectName = subjectsData
      .find((subject) => subject.id == formData.subject_id)
      ?.s_name.replace(/ /g, "_");

    const termName = termsData
      .find((term) => term.id == formData.term_id)
      ?.t_name.replace(/ /g, "_");

    const year = formData.year.replace(/ /g, "_");
    // console.log(subjectName, termsData);
    const filename = `${year}_${gradesNames}_${subjectName}_${termName}.pdf`;

    const blobUrl = URL.createObjectURL(pdfData);
    const url = window.URL.createObjectURL(pdfData);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename; // Customizable filename
    link.click();
  };

  const viewPdf = async () => {
    if (!pdfData) {
      toast.error(
        "No PDF available to view yet. Please generate the PDF first."
      );
      return;
    }
    try {
      const blobUrl = URL.createObjectURL(pdfData);

      // Open the PDF in a new tab
      window.open(blobUrl, "_blank"); // Use "_blank" to specify a new tab

      // Optionally, revoke the Blob URL after the user opens the PDF
      // to prevent memory leaks
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error viewing PDF:", error);
      toast.error("An error occurred. Please try again");
    }

    // const blob = new Blob([pdfData], { type: "application/pdf" });
    // const url = window.URL.createObjectURL(blob);
    // window.open(url, "_blank"); // Opens the PDF in a new tab
  };

  if (loading) return <Loading />;

  return (
    <div className="w-100 flex flex-col gap-5">
      <div className="flex justify-start">
        {/* {Customize.icon} */}
        <div className="flex flex-row justify-start">
          <p className=" w-fit font-bold text-xl px-3 py-5 border-primary uppercase tracking-wider">
            Generate ( PDF & EXCEL & Zip File )
          </p>
        </div>
      </div>

      <form className="w-100">
        <div className="w-100 grid grid-cols-1 sm:grid-cols-2 pt-10 gap-10  bg-white p-2 rounded-lg">
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="grade_ids" className=" text-sm font-medium">
              Grade:<span className="text-red-600">*</span>
            </label>
            {/* <select
              id="grade_ids"
              name="grade_ids"
              value={formData.grade_ids}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Grade --</option>
              {gradesData.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.g_name}
                </option>
              ))}
            </select> */}
            <Select
              isMulti // Enable multi-select
              id="grade_ids"
              name="grade_ids" // Name for the generated array
              defaultValue={formData.grade_ids} // Initial selection
              value={formData.grade_ids} // Controlled value
              className="flex-grow"
              placeholder="-- Select Grade --"
              onChange={
                handleChangeGrades
                //   (newValue) => {
                //   setFormData({
                //     ...formData,
                //     grade_ids: newValue,
                //   });
                //   // Update the multi-select value
                //   // setValue("SubjectDivisions", newValue);
                // }
              }
              options={gradesData}
              isSearchable
              isClearable
              getOptionLabel={(option) => option.g_name}
              getOptionValue={(option) => option.id}
            />
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="subject_id" className=" text-sm font-medium">
              Subject:<span className="text-red-600">*</span>
            </label>
            <select
              id="subject_id"
              name="subject_id"
              value={formData.subject_id}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Subject --</option>
              {subjectsData.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.s_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="term_id" className=" text-sm font-medium">
              Term:<span className="text-red-600">*</span>
            </label>
            <select
              id="term_id"
              name="term_id"
              value={formData.term_id}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Term --</option>
              {termsData.map((term) => (
                <option key={term.id} value={term.id}>
                  {term.t_name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="year" className=" text-sm font-medium">
              Year:<span className="text-red-600">*</span>
            </label>
            <select
              id="year"
              name="year"
              value={formData.year}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Year --</option>
              {yearsData.map((year) => (
                <option key={year.id} value={year.y_name}>
                  {year.y_name}
                </option>
              ))}
            </select>
          </div>
          {/* Types Selection */}
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="type_id" className="text-sm font-medium">
              Type:<span className="text-red-600">*</span>
            </label>
            <select
              id="type_id"
              name="type_id"
              value={formData.type_id}
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Type --</option>
              {typesData.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          {/* active Selection */}
          <div className="col-span-2 sm:col-span-1 flex flex-row gap-4">
            <label htmlFor="active" className="text-sm font-medium">
              Active:<span className="text-red-600">*</span>
            </label>
            <select
              id="active"
              name="active" // Changed from type_id to active_status
              value={formData.active} // Make sure this matches your formData structure
              onChange={handleChange}
              className="flex-grow rounded border px-3 py-2 focus:outline-none focus:ring-primary focus:ring-opacity-50"
            >
              <option value="">-- Select Activation --</option>
              <option value="1">Active</option> {/* Value for Active */}
              <option value="0">Inactive</option> {/* Value for Inactive */}
            </select>
          </div>
          {/* Add additional input fields as needed */}
          <div className="w-100 flex flex-row gap-3 py-5 col-span-2">
            <button
              type="submit"
              onClick={createPDF}
              className="w-fit mx-auto py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
            >
              {generateLoading ? (
                <Loading size="sm" color="text-white" />
              ) : (
                "Generate PDF"
              )}
            </button>
            <button
              type="submit"
              onClick={createExcel}
              className="w-fit mx-auto py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
            >
              {generateExcelLoading ? (
                <Loading size="sm" color="text-white" />
              ) : (
                "Generate EXCEL"
              )}
            </button>
            <button
              type="submit"
              onClick={createZipFile}
              className="w-fit mx-auto py-2 px-6 bg-primary text-white font-medium rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2"
            >
              {generateZipLoading ? (
                <Loading size="sm" color="text-white" />
              ) : (
                "Generate ZIP File"
              )}
            </button>
          </div>
        </div>
      </form>
      <Model
        isOpen={isOpenModel}
        onClose={handleCloseModel}
        title="Download & view (EXCEL)"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-slate-700">EXCEL generated successfully</h3>
          <div className="flex flex-col gap-4 justify-center">
            <div className="flex flex-row gap-5 justify-center">
              <button
                onClick={downloadExcel}
                className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
              >
                Download EXCEL
              </button>
            </div>
          </div>
        </div>
      </Model>
      <Model
        isOpen={isOpenZipModel}
        onClose={handleCloseZipModel}
        title="Download & view (Zip File)"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-slate-700">Zip File generated successfully</h3>
          <div className="flex flex-col gap-4 justify-center">
            <div className="flex flex-row gap-5 justify-center">
              <button
                onClick={downloadZip}
                className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
              >
                Download Zip File
              </button>
            </div>
          </div>
        </div>
      </Model>
      <Model
        isOpen={isOpenPDFModel}
        onClose={handleClosePDFModel}
        title="Download & view (PDF)"
      >
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-slate-700">PDF generated successfully</h3>
          <div className="flex flex-col gap-4 justify-center">
            <div className="flex flex-row gap-5">
              <button
                onClick={downloadPdf}
                className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-primary hover:bg-opacity-95 text-white"
              >
                Download PDF
              </button>
              <button
                onClick={viewPdf}
                className="w-fit mx-auto px-7 py-2 rounded-md text-md font-medium bg-yellow-600 hover:bg-opacity-95 text-white"
              >
                View PDF
              </button>
            </div>
          </div>
        </div>
      </Model>
    </div>
  );
};
