import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RequireAuth from "@auth-kit/react-router/RequireAuth";

import {
  Grades,
  ArchivedGrades,
  Home,
  Login,
  Years,
  Subjects,
  Terms,
  YearsArchives,
  ArchivedSubjects,
  TermsArchives,
  CreateQR,
  QR,
  Bulk,
  Users,
  Export,
  UpdateProfile,
} from "./pages";
import ProtectedLayout from "./layouts/protectedLayout";
import { UpdateQr } from "./pages/updateQr";
import Test from "./pages/Test";
import { Teachers } from "./pages/teachers";
import { QrInfo } from "./pages/qrInfo";

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/login"} element={<Login />} />
        <Route path={"/test"} element={<Test />} />
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Home />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/users"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Users />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/teachers"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Teachers />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/grades"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Grades />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/Bulk"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Bulk />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/archived-grades"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <ArchivedGrades />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/subjects"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Subjects />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/archived-subjects"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <ArchivedSubjects />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/years"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Years />
              </RequireAuth>
            }
          />
        </Route>

        <Route element={<ProtectedLayout />}>
          <Route
            path={"/archived-years"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <YearsArchives />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/terms"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Terms />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/archived-terms"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <TermsArchives />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/create-new-qr"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <CreateQR />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
        
          <Route
            path={"/QR"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <QR />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/Update_qr_info/:qr_code"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <UpdateQr />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/qr-info/:qr_code"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <QrInfo />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/export"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <Export />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route
            path={"/update-profile"}
            element={
              <RequireAuth fallbackPath={"/login"}>
                <UpdateProfile />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
