import toast from "react-hot-toast";
import { ApiHandler } from "./index";
import axios from "axios";

const api = new ApiHandler();

const qrApiEndPoint = {
  createQr: async (data, token) => {
    try {
      const res = await api.postWithFile("/add", data, token);
      return res;
    } catch (error) {
      console.error("Error adding grades:", error);
    }
  },
  bulk: async (data, token) => {
    try {
      const res = await api.post("/bulk", data, token);
      return res;
    } catch (error) {
      console.error("Error adding grades:", error);
    }
  },

  getqrnum: async (token, data) => {
    try {
      // data => {page, per_page}
      const res = await api.post("/getqrnum", data, token);
      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /year/view:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },

  searchQr: async (token, data) => {
    try {
      const res = await api.post("/search_qr", data, token);
      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /year/view:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  updateQr: async (token, data) => {
    try {
      const res = await api.postWithFile("/update", data, token);
      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /year/view:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  GetOneQR: async (token, data) => {
    try {
      const res = await api.get(`/get/oneqr/${data}`, token);
      return res;
    } catch (error) {
      toast.error("This didn't work.");

      console.error("Error fetching /year/view:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },

  // getqrnum: async (token, data) => {
  //   try {
  //     // data => {page, per_page}
  //     const res = await api.post("/getqrnum", data, token);
  deleteQr: async (token, data) => {
    try {
      const res = await api.post("/delete_qr", data, token);
      return res;
    } catch (error) {
      console.error("Error deleting qr", error);
    }
  },
  deactiveQr: async (token, data) => {
    try {
      const res = await api.post("/deactive_qr", data, token);
      return res;
    } catch (error) {
      console.error("Error dactive qr", error);
    }
  },
  activeQr: async (token, data) => {
    try {
      const res = await api.post("/active_qr", data, token);
      return res;
    } catch (error) {
      console.error("Error dactive qr", error);
    }
  },
};

export default qrApiEndPoint;
